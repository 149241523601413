import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
  CanceledError,
} from "axios";

import { STATUS_CODES } from "Constants/Common.Constant";
import { localesText } from "Utils/LocalesText.Util";
import { normalizeError } from "Utils/NomalizeError.Util";

let controller = new AbortController();
export const createAxios = (requestOptions?: AxiosRequestConfig) => {
  const axiosInstance = axios.create({
    withCredentials: false,
  });

  const interceptorsRequest = async (config: any) => {
    config.baseURL = "https://api-app.helloclever.co/public_api/v2";
    config.timeout = 1000 * 60 * 10; // miliseconds - 10 mins
    config.signal = controller.signal;
    const customConfigs = { ...config, ...requestOptions };
    return customConfigs;
  };

  const interceptorsResponse = async (response: AxiosResponse) => response;

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    interceptorsRequest,
    (error: AxiosError) => Promise.reject(error),
  );

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    interceptorsResponse,
    (error: AxiosError) => Promise.reject(handleDataError(error)),
  );

  return axiosInstance;
};
const handleDataError = (error: AxiosError) => {
  let message;

  if (
    error?.code === CanceledError.ERR_BAD_REQUEST ||
    error.response?.status === STATUS_CODES.INTERNAL_SERVER_ERROR
  ) {
    handleAbort();

    message = localesText("errors.oops_something_went_wrong");
  } else if (error.request?.status === STATUS_CODES.NO_INTERNET) {
    handleAbort();
    message = localesText("errors.oops_something_went_wrong");
  } else if (error.response?.status === STATUS_CODES.TOO_MANY_REQUESTS) {
    message = localesText("errors.too_many_request");
  } else if (error.response?.status === STATUS_CODES.UNAUTHORIZED) {
    handleAbort();

    message = localesText("errors.token_expired");
  } else {
    const normalized = normalizeError(error);
    message = normalized;
  }

  if (!message || message.length < 1) {
    message = localesText("errors.oops_something_went_wrong");
  }

  return { message };
};

const handleAbort = () => {
  controller.abort();
  controller = new AbortController();
};

const instance = createAxios();

export default instance;
