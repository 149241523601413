import axios from "axios";

import api from "Configs/Axios.Config";
import { API } from "Constants/API.Constant";
import { _get } from "Utils/Lodash.Util";
import { generatePath } from "Utils/Common.Util";

export const getInstantDeals = async (params?: {
  page?: number;
  size?: number;
  random?: boolean;
}) => {
  try {
    const response = await api.get(API.INSTANT_DEALS, {
      params: {
        page: params?.page ?? 1,
        size: params?.size ?? 5,
        random: !!params?.random,
      },
    });
    const records = _get(response.data, "records", []);

    return { success: true, records };
  } catch (error) {
    return { success: false, records: [] };
  }
};
export const getInstantDealDetail = async (slug: any) => {
  try {
    const response = await api.get(
      generatePath(API.INSTANT_DEAL_DETAIL, { slug }),
    );

    const record = _get(response.data, "record", {});

    return { success: true, record };
  } catch (error) {
    return { success: false, record: {} };
  }
};

export const getReferralBonus = async () => {
  try {
    const response = await api.get(API.REFERRAL_BONUS);
    const amount = _get(response.data, "referral_bonus_amount", 0);

    return { success: true, amount };
  } catch (error) {
    return { success: false, amount: 0 };
  }
};

export const getStoresCount = async () => {
  try {
    const response = await api.get(API.STORES_COUNT);
    const storeCount = _get(response.data, "clever_shop", 0);
    const instantStoreCount = _get(response.data, "clever_shop_instant", 0);

    return { success: true, storeCount, instantStoreCount };
  } catch (error) {
    return { success: false, storeCount: 0, instantStoreCount: 0 };
  }
};

export const getBlogs = async (per_page?: number) => {
  try {
    const response = await api.get(API.MEDIUM_BLOGS, {
      params: { per_page: per_page || 100 },
    });
    return _get(response, "data", []) || [];
  } catch (error) {
    return [];
  }
};

export const getBanks = async () => {
  try {
    const response = await api.get(API.BANKS);
    const banks = Array.isArray(response.data) ? response.data : [];
    return { success: true, banks };
  } catch (error) {
    return { success: false, banks: [] };
  }
};

export const getApiStatusService = async () => {
  try {
    const response = await axios.get(API.API_STATUS);

    const apiStatus = Array.isArray(response.data.data)
      ? response.data.data
      : [];
    return { success: true, apiStatus };
  } catch (error) {
    return { success: false, apiStatus: [] };
  }
};
